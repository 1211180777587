.paginator-start {
  .mat-paginator-container {
    justify-content: flex-start;
  }
}

.paginator-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  padding-bottom: 7px;

  .mat-paginator-container {
    justify-content: flex-start;
  }
}
