@use '@angular/material' as mat;

@mixin time-input-theme($config-or-theme) {
  $foreground-palette: map-get($config-or-theme, foreground);
  $warn: map-get($config-or-theme, warn);
  .ng-invalid.ng-touched {
    > .time-input {
      border-color: mat.get-color-from-palette($warn);
    }
  }

  .time-input {
    -webkit-appearance: none;
    padding: 2px 0 1px 2px;
    border: 1.5px solid mat.get-color-from-palette($foreground-palette, default);
    border-radius: 5px;
    box-shadow: none;
    background: transparent;
    color: mat.get-color-from-palette($foreground-palette, text);

    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
    }
  }
}
