@use 'sass:map';
@use '@angular/material' as mat;

.dark-theme a {
  color: #3ea1dc;

  &:visited {
    color: #a05dde;
  }
}

@mixin include-link-styles($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  a {
    color: mat.get-color-from-palette($primary-palette, 400);
    transition: color 0.2s ease-in-out;

    &:hover {
      color: mat.get-color-from-palette($primary-palette, 600);
    }

    &:visited {
      color: map-get($theme, link-visited-color);
    }
  }
}
