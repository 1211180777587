.mat-chip-list {
  &--nowrap {
    .mat-chip-list-wrapper {
      display: flex;
      flex-wrap: nowrap;
    }
  }
}

.mat-chip {
  white-space: nowrap;
}
