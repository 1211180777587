tremaze-file-selector-input + .mat-form-field-label-wrapper {
  .mat-form-field-label.mat-empty {
    text-align: center;
    top: calc(50% - 21px);
  }
}

tremaze-file-selector-input.noActions + .mat-form-field-label-wrapper {
  .mat-form-field-label.mat-empty {
    text-align: center;
    top: calc(50%);
  }
}
