@use '@angular/material' as mat;
@use 'sass:map';

@mixin tremaze-mat-button-theme($light-theme) {

  $primary-palette: mat.get-color-from-palette($light-theme, primary);
  $primary-contrast: map.get($primary-palette, contrast);

  $primary-contrast-500: map.get($primary-contrast, 500);

  $accent-palette: mat.get-color-from-palette($light-theme, accent);
  $accent-contrast: map.get($accent-palette, contrast);

  $accent-contrast-500: map.get($accent-contrast, 500);

  .mat-mdc-raised-button:not(:disabled), .mat-mdc-unelevated-button, .mat-mdc-raised-button, .mat-mdc-mini-fab, .mat-mdc-fab {
    &.mat-primary {
      color: $primary-contrast-500 !important;
    }

    &.mat-accent {
      color: $accent-contrast-500 !important;
    }
  }

  .mdc-button {
    //letter-spacing: normal !important;
    border-radius: 12px !important;

    &--raised {
      box-shadow: rgba(0, 0, 0, 0.05) 0 0 12px !important;
    }

    font-weight: bold;
  }

}

.mdc-icon-button {
  &.small-icon-button {
    height: 1.5rem !important;
    width: 1.5rem !important;
    font-size: 1.1rem !important;
    padding: .4rem !important;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .mat-mdc-button-touch-target {
      height: inherit !important;
      width: inherit !important;
    }
  }
}
