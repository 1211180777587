button.custom-button {
  border: none;
  background: none;
  padding: 0;
  color: #00000087;
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 8px;
  }
}

.dark-theme button.custom-button {
  color: #ffffffa8;
}
