.tox.tox-tinymce {
  flex: 1 !important;
}

.ng-animating {
  editor > textarea {
    background-color: transparent !important;
    border: none !important;
  }
}
