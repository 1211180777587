@import url("https://assets.tremaze.de/fonts/Roboto/fontface.css");
.center-children-vertically, .center-children {
  display: flex;
  align-items: center;
}

.center-children-horizontally, .center-children {
  display: flex;
  justify-content: center;
}

.center-text {
  text-align: center;
}

.d-flex {
  display: flex;
}

mat-accordion mat-expansion-panel mat-expansion-panel-header {
  height: 40px;
}
mat-accordion mat-expansion-panel .mat-expansion-panel-content {
  height: 0;
}
mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header {
  height: 64px;
}
mat-accordion mat-expansion-panel.mat-expanded .mat-expansion-panel-content {
  height: auto;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.spacer {
  flex: 1 1 0;
}

.d-none {
  display: none !important;
}

.green-color {
  color: green;
}

.red-color {
  color: red;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
             supported by Chrome, Edge, Opera and Firefox */
}

.custom-scrollbar, .mat-dialog-content .mat-stepper-horizontal .mat-horizontal-content-container, .mat-dialog-content, .table-wrapper__container__table-wrapper, .page__body, .page__Body, html,
body {
  scrollbar-width: thin;
  scrollbar-color: #a8a8a8;
}
.custom-scrollbar::-webkit-scrollbar, .mat-dialog-content .mat-stepper-horizontal .mat-horizontal-content-container::-webkit-scrollbar, .mat-dialog-content::-webkit-scrollbar, .table-wrapper__container__table-wrapper::-webkit-scrollbar, .page__body::-webkit-scrollbar, .page__Body::-webkit-scrollbar, html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: 0 0;
  padding-right: 3px;
  padding-bottom: 3px;
}
.custom-scrollbar::-webkit-scrollbar-thumb, .mat-dialog-content .mat-stepper-horizontal .mat-horizontal-content-container::-webkit-scrollbar-thumb, .mat-dialog-content::-webkit-scrollbar-thumb, .table-wrapper__container__table-wrapper::-webkit-scrollbar-thumb, .page__body::-webkit-scrollbar-thumb, .page__Body::-webkit-scrollbar-thumb, html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 2px;
  margin-right: 3px;
  margin-bottom: 3px;
}

blockquote {
  border-left: 3px solid;
  margin-left: 10px;
  padding: 10px 0 10px 10px;
  border-color: lightgray;
}
blockquote > *:first-child {
  margin: 0;
}

/*------------------------------------*\
    #PAGE
\*------------------------------------*/
html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.cdk-drag-handle:not(.disabled) {
  cursor: grab;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .cdk-drag:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
  opacity: 0;
}

hr {
  margin: 0;
}

::ng-deep .ng-animating div mat-accordion mat-expansion-panel mat-expansion-panel-header {
  height: 48px;
}

::ng-deep .ng-animating div mat-accordion mat-expansion-panel div.mat-expansion-panel-content {
  height: 0px;
  visibility: hidden;
}

.fade-animation-outlet-wrapper {
  position: relative;
  flex: 1;
  overflow: hidden !important;
}
.fade-animation-outlet-wrapper > router-outlet ~ * {
  position: absolute;
  width: 100%;
  height: 100%;
}
.fade-animation-outlet-wrapper > router-outlet ~ * > * {
  height: 100%;
}

table {
  width: 100%;
}

.page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}
.page__head, .page__Head {
  padding: 10px 20px;
  z-index: 2;
  display: flex;
  align-items: center;
}
.page__head:not(.page__head__flat, .page__Head__flat), .page__Head:not(.page__head__flat, .page__Head__flat) {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.page__head--title-center, .page__Head--title-center {
  justify-content: center;
  text-align: center;
}
.page__title, .page__Title {
  font-size: 1.75rem;
  margin: 0;
}
.page__body, .page__Body {
  flex: 1;
  overflow: auto;
}
.page__body--no-scroll, .page__Body--no-scroll {
  overflow: hidden;
}
.page__body__empty, .page__Body__empty {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.page__body__empty mat-icon, .page__Body__empty mat-icon {
  font-size: 35px;
}
.page__body__empty *, .page__Body__empty * {
  text-align: center;
}
.page__actions, .page__Actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px;
  border-top: 1px solid rgba(211, 211, 211, 0.25);
}
.page__actions > *:not(:last-child), .page__Actions > *:not(:last-child) {
  margin-right: 10px;
}

.center-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

tremaze-main tremaze-main-nav-header ~ mat-nav-list .mdc-list-item-content {
  padding: 0 !important;
}
tremaze-main tremaze-main-nav-header ~ mat-nav-list .mdc-list-item-content > a {
  padding: 0 16px;
}

.form {
  display: flex;
  flex-direction: column;
}
.form__row {
  display: flex;
  margin-bottom: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}
.form__row .mat-form-field {
  min-width: 250px;
  width: 100%;
  max-width: 500px;
}
.form__col {
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.form__col:not(:last-child) {
  margin-right: 10px;
}

.form-grid-wrapper {
  display: grid;
  grid-gap: 1rem;
}

.form-grid-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 2rem;
  align-items: flex-start;
  padding: 1rem 0;
  width: min(75rem, 100%);
}
.form-grid-container--Max-Cols-2 .form-grid {
  grid-template-columns: repeat(auto-fill, minmax(max(40%, 18.75rem), 1fr)) !important;
}
@media screen and (max-width: 400px) {
  .form-grid-container--Max-Cols-2 .form-grid {
    grid-template-columns: repeat(auto-fill, minmax(max(20%, 12.5rem), 1fr)) !important;
  }
}
.form-grid-container--Max-Cols-1 .form-grid {
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)) !important;
  min-width: 100%;
}
.form-grid-container > * {
  min-width: min(25rem, 100%);
  max-width: 100%;
  flex: 1;
}
.form-grid-container > .form-grid-container {
  min-width: 100%;
}

.form-grid-item-new-row {
  grid-column-start: 1;
}

.form-grid-item-2-col {
  grid-column: span 2;
}

.form-grid-item-full-width, .form-grid > h1,
.form-grid > h2,
.form-grid > h3,
.form-grid > h4,
.form-grid > h5,
.form-grid > h6, .form-grid fieldset > h1,
.form-grid fieldset > h2,
.form-grid fieldset > h3,
.form-grid fieldset > h4,
.form-grid fieldset > h5,
.form-grid fieldset > h6 {
  grid-column: 1/-1;
}

mat-tab-body > :first-child > fieldset:first-child {
  margin-top: 15px;
}

.dark-theme fieldset legend {
  color: rgb(211, 211, 211);
}

fieldset {
  margin-inline-start: 0;
  margin-inline-end: 0;
  border-color: rgba(128, 128, 128, 0.2);
  border-radius: 10px;
  background-color: rgba(128, 128, 128, 0.04);
  padding-bottom: 20px;
}
fieldset legend {
  color: #4c4c4c;
}

.form-grid, .form-grid fieldset {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(15.625rem, 100%), 1fr));
  grid-gap: 1rem;
  align-items: baseline;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
}
.form-grid--dense, .form-grid fieldset--dense {
  grid-gap: 0.333rem;
}
.form-grid .mat-form-field-appearance-outline .mat-form-field-wrapper, .form-grid fieldset .mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0;
}
.form-grid > h1,
.form-grid > h2,
.form-grid > h3,
.form-grid > h4,
.form-grid > h5,
.form-grid > h6, .form-grid fieldset > h1,
.form-grid fieldset > h2,
.form-grid fieldset > h3,
.form-grid fieldset > h4,
.form-grid fieldset > h5,
.form-grid fieldset > h6 {
  margin: 0;
}
.form-grid--force-vertical, .form-grid fieldset--force-vertical {
  grid-template-columns: 1fr;
}
.form-grid--Center-Items, .form-grid fieldset--Center-Items {
  place-items: start center;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.innerHTML ul {
  list-style-type: revert;
  padding: revert;
  margin: revert;
}

table:not(.default-table) tbody tr:hover {
  background-color: rgba(136, 136, 136, 0.1) !important;
}

.dark-theme .default-table thead {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1411764706);
}

.default-table {
  border-collapse: collapse;
}
.default-table thead th {
  font-size: 1.15rem;
  font-weight: bold;
}
.default-table td {
  padding: 12px 5px;
  vertical-align: top;
}
.default-table th {
  text-align: left;
}
.default-table tr {
  transition: background-color 0.2s ease-in-out;
}

.table-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.table-wrapper__container {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
}
.table-wrapper__container__table-wrapper {
  overflow: auto;
  flex: 1;
}
.table-wrapper__container__table-wrapper table {
  margin-bottom: 30px;
}
.table-wrapper__container__table-wrapper__reload {
  margin-left: auto;
}
.table-wrapper__container__filter {
  display: flex;
  padding: 10px 20px 0 10px;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.table-wrapper__container__filter__reset {
  margin-right: 20px;
  cursor: pointer;
}
.table-wrapper__container__filter__reset:hover, .table-wrapper__container__filter__reset:focus {
  text-decoration: underline;
}

.dark-theme a {
  color: #3ea1dc;
}
.dark-theme a:visited {
  color: #a05dde;
}

button.custom-button {
  border: none;
  background: none;
  padding: 0;
  color: rgba(0, 0, 0, 0.5294117647);
  cursor: pointer;
}
button.custom-button:not(:first-child) {
  margin-left: 8px;
}

.dark-theme button.custom-button {
  color: rgba(255, 255, 255, 0.6588235294);
}

.dark-theme input[type=time]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.mat-input-element[type=date]::after, .mat-input-element[type=datetime]::after, .mat-input-element[type=datetime-local]::after, .mat-input-element[type=month]::after, .mat-input-element[type=week]::after, .mat-input-element[type=time]::after {
  content: none !important;
}

blockquote {
  border-left: 3px solid;
  margin-left: 10px;
  padding: 10px 0 10px 10px;
  border-color: lightgray;
}
blockquote > *:first-child {
  margin: 0;
}

gridster {
  background: transparent !important;
}
gridster gridster-item {
  background: transparent !important;
  margin: 10px;
  border-radius: 5px;
}
* > :last-child gridster gridster-item > :first-child {
  height: 100%;
}
gridster gridster-item .mat-card-header {
  display: flex;
  justify-content: space-between;
}
gridster gridster-item mat-card {
  box-shadow: none !important;
  position: static !important;
}
gridster gridster-item .table-wrapper__container, gridster gridster-item .table-wrapper__container > mat-card, gridster gridster-item .table-wrapper__container__filter {
  padding-top: 0 !important;
}
gridster gridster-item .table-wrapper__container > mat-card {
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 0 !important;
}

.grid-item-content > *:not(.grid-item-content__expand-button) {
  height: 100%;
}

.mdc-icon-button.small-icon-button {
  height: 1.5rem !important;
  width: 1.5rem !important;
  font-size: 1.1rem !important;
  padding: 0.4rem !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.mdc-icon-button.small-icon-button .mat-mdc-button-touch-target {
  height: inherit !important;
  width: inherit !important;
}

.mat-mdc-table .mat-column-actions {
  width: 250px;
  overflow: auto;
  white-space: nowrap;
}
.mat-mdc-table.dense-actions .mat-column-actions {
  max-width: 120px;
  width: 120px;
}
.mat-mdc-table .mat-cell:not(:first-child) {
  padding-left: 5px;
}
.mat-mdc-table .mat-cell:not(:last-child) {
  padding-right: 5px;
}
.mat-mdc-table .mat-header-cell {
  padding-left: 7px;
}

mat-card {
  display: flex !important;
  flex-direction: column !important;
  box-sizing: border-box !important;
  border-radius: 25px !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 25px !important;
}
mat-card .mat-mdc-card-header {
  padding-bottom: 8px !important;
}
mat-card mat-card-content {
  flex: 1 !important;
  overflow: hidden;
}
mat-card.enableScroll mat-card-content {
  overflow: auto;
}

.custom-scrollbar, .mat-dialog-content .mat-stepper-horizontal .mat-horizontal-content-container, .mat-dialog-content, html,
body, .page__body, .page__Body, .table-wrapper__container__table-wrapper {
  scrollbar-width: thin;
  scrollbar-color: #a8a8a8;
}
.custom-scrollbar::-webkit-scrollbar, .mat-dialog-content .mat-stepper-horizontal .mat-horizontal-content-container::-webkit-scrollbar, .mat-dialog-content::-webkit-scrollbar, html::-webkit-scrollbar,
body::-webkit-scrollbar, .page__body::-webkit-scrollbar, .page__Body::-webkit-scrollbar, .table-wrapper__container__table-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: 0 0;
  padding-right: 3px;
  padding-bottom: 3px;
}
.custom-scrollbar::-webkit-scrollbar-thumb, .mat-dialog-content .mat-stepper-horizontal .mat-horizontal-content-container::-webkit-scrollbar-thumb, .mat-dialog-content::-webkit-scrollbar-thumb, html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb, .page__body::-webkit-scrollbar-thumb, .page__Body::-webkit-scrollbar-thumb, .table-wrapper__container__table-wrapper::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 2px;
  margin-right: 3px;
  margin-bottom: 3px;
}

.transparent-backdrop {
  background-color: transparent !important;
}

.dialog-no-padding .mat-dialog-container {
  padding: 0;
}

.relative-dialog .mat-dialog-container {
  position: relative;
}

.mat-dialog-container {
  max-height: 100vh !important;
  overflow: hidden !important;
  display: flex !important;
  flex-direction: column !important;
  border-radius: 25px !important;
}
.mat-dialog-container > *:first-child {
  display: contents;
}

@media (max-width: 980px) {
  .mat-dialog-content {
    max-height: unset !important;
  }
}
.mat-dialog-content .mat-tab-body-content {
  overflow: auto !important;
}
.mat-dialog-content .mat-stepper-horizontal {
  max-height: 610px;
  display: flex;
  flex-direction: column;
}
.mat-dialog-content .mat-stepper-horizontal .mat-horizontal-content-container {
  overflow-y: auto;
}

@media (max-width: 980px) {
  .cdk-overlay-pane {
    max-width: 100vw !important;
  }
}

.mat-tab-group.full-height-tab-group {
  height: 100%;
}
.mat-tab-group.full-height-tab-group .mat-tab-body-wrapper {
  flex: 1;
}

.cdk-column-logo,
.cdk-column-avatar,
.cdk-column-photo {
  max-width: 40px !important;
  min-width: 40px !important;
  width: 40px !important;
  box-sizing: content-box !important;
  padding-right: 0 !important;
}

.mat-ink-bar {
  height: 4px !important;
}

.mdc-tab {
  letter-spacing: 0.02em !important;
}

.hide-cb {
  color: white !important;
  cursor: pointer !important;
}
.hide-cb .mat-pseudo-checkbox {
  display: none !important;
}

.mat-option-text .mat-icon {
  margin-left: 5px;
  font-size: 22px;
}

.mat-mdc-option {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.paginator-start .mat-paginator-container {
  justify-content: flex-start;
}

.paginator-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  padding-bottom: 7px;
}
.paginator-wrapper .mat-paginator-container {
  justify-content: flex-start;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
.mat-mini-fab .mat-button-wrapper {
  line-height: 21px !important;
}

.mat-horizontal-stepper-content:not(.mat-horizontal-stepper-content-inactive) {
  padding-top: 12px;
}

.no-content-padding .mat-horizontal-content-container {
  padding-left: 0;
  padding-right: 0;
}

tremaze-file-selector-input + .mat-form-field-label-wrapper .mat-form-field-label.mat-empty {
  text-align: center;
  top: calc(50% - 21px);
}

tremaze-file-selector-input.noActions + .mat-form-field-label-wrapper .mat-form-field-label.mat-empty {
  text-align: center;
  top: 50%;
}

.mat-icon-button .lnr {
  font-size: 22px;
}

tremaze-routed-tab-view {
  display: flex;
  flex-direction: column;
  height: 100%;
}
tremaze-routed-tab-view .routeProxy {
  display: flex;
  flex-direction: column;
  height: 100%;
}
tremaze-routed-tab-view router-outlet ~ *:not(.routeProxy) {
  flex: 1;
  position: static;
  height: auto;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
}
tremaze-routed-tab-view router-outlet ~ *:not(.routeProxy) > * {
  flex: 1;
}

.mat-form-field {
  display: block !important;
  font-size: 14px;
  letter-spacing: normal !important;
}

.mat-form-field-infix {
  width: auto !important;
}

.mat-form-field-outline-start {
  border-radius: 12px 0 0 12px !important;
  min-width: 12px !important;
}

.mat-form-field-outline-end {
  border-radius: 0 12px 12px 0 !important;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) translateX(0.65em) scale(0.75) !important;
}

fieldset > mat-form-field-appearance-outline:first-child, fieldset > legend:first-child + .mat-form-field-appearance-outline {
  margin-top: 10px;
}

.mat-form-field-appearance-fill.mat-form-field-transparent > div {
  background-color: transparent !important;
}

.mat-mdc-form-field-icon-suffix:not(:has(.mdc-icon-button)) {
  padding-right: 0.5em !important;
  padding-top: 0.9em !important;
}

.comment__text > *:first-child {
  margin: 0 !important;
  padding: 0 !important;
}

.mat-progress-bar-invisible-background .mat-progress-bar-background,
.mat-progress-bar-invisible-background .mat-progress-bar-buffer {
  display: none !important;
}

.tox.tox-tinymce {
  flex: 1 !important;
}

.ng-animating editor > textarea {
  background-color: transparent !important;
  border: none !important;
}

.ordered-mat-list .mat-list-item-content {
  flex: 1;
}

.mat-mdc-list-item-unscoped-content {
  display: flex;
  align-items: center;
}

.mdc-list-item__primary-text {
  overflow: visible !important;
}

image-cropper .overlay {
  outline-color: transparent !important;
}

.mat-menu-item .lnr {
  transform: translateY(-3px);
  display: inline-block;
  font-size: 22px;
}

.mat-chip-list--nowrap .mat-chip-list-wrapper {
  display: flex;
  flex-wrap: nowrap;
}

.mat-chip {
  white-space: nowrap;
}

mat-icon {
  overflow: visible !important;
}

.badge {
  border-radius: 7px;
  text-align: center;
  font-size: 12px;
  padding: 1px 3px;
  min-width: 1.15em;
  display: inline-block;
  line-height: 1.5em;
}

tremaze-dashboard-sub-content tremaze-data-table > .table-wrapper__container > mat-card {
  box-shadow: none !important;
  padding: 0 !important;
  border-radius: 0 !important;
  margin: 0 !important;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

html {
  font-family: Roboto;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled), .mat-mdc-option:focus:not(.mdc-list-item--disabled), .mat-mdc-option.mat-mdc-option-active, .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #009958);
}

.mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #004b58);
}

.mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #009958;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #009958;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #004b58;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #004b58;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #004b58;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #004b58;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-option {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-body1-font-size, 16px);
  line-height: var(--mdc-typography-body1-line-height, 16px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled), .mat-mdc-option:focus:not(.mdc-list-item--disabled), .mat-mdc-option.mat-mdc-option-active, .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #009958);
}

.mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #004b58);
}

.mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #009958;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #009958;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #004b58;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #004b58;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #004b58;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #004b58;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-option {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-body1-font-size, 16px);
  line-height: var(--mdc-typography-body1-line-height, 16px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
}

.mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #fff;
}

.mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #e0e0e0;
}

.mat-mdc-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-card-title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-headline6-font-size, 20px);
  line-height: var(--mdc-typography-headline6-line-height, 32px);
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: var(--mdc-typography-headline6-text-transform, none);
}

.mat-mdc-card-subtitle {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-subtitle2-font-size, 14px);
  line-height: var(--mdc-typography-subtitle2-line-height, 22px);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #009958;
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(0, 153, 88, 0.25)'/%3E%3C/svg%3E");
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(0, 153, 88, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #004b58;
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(0, 75, 88, 0.25)'/%3E%3C/svg%3E");
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(0, 75, 88, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: Roboto;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #009958);
}
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-text-field--filled:hover .mdc-text-field__ripple::before, .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #009958);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #009958);
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-text-field--outlined .mdc-text-field__ripple::before,
.mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 153, 88, 0.87);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field-character-counter,
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field-character-counter,
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(0, 153, 88, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(0, 75, 88, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #004b58);
}
.mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #004b58);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 75, 88, 0.87);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #004b58);
}

.mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mdc-text-field__input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
.mdc-text-field__affix {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-text-field--textarea .mdc-text-field__input {
  line-height: 1.5rem;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}

[dir=rtl] .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 1.3);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-form-field,
.mat-mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-body1-font-size, 16px);
  line-height: var(--mdc-typography-body1-line-height, 16px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: calc(16px * var(--mat-mdc-form-field-floating-label-scale, 0.75));
}
.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 16px;
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #009958);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #009958);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(0, 153, 88, 0.87);
}
.mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(0, 75, 88, 0.87);
}
.mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-select-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 16px;
}

.mat-mdc-select {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-body1-font-size, 16px);
  line-height: var(--mdc-typography-body1-line-height, 16px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #009958);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #009958);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-autocomplete-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 16px;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: Roboto;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Roboto;
  --mdc-dialog-supporting-text-line-height: 16px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #009958;
  --mdc-chip-elevated-disabled-container-color: #009958;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #004b58;
  --mdc-chip-elevated-disabled-container-color: #004b58;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}

.mat-mdc-chip-focus-overlay {
  background: black;
}

.mat-mdc-chip {
  height: 32px;
}

.mat-mdc-standard-chip {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #00a363;
  --mdc-switch-selected-handle-color: #00a363;
  --mdc-switch-selected-hover-state-layer-color: #00a363;
  --mdc-switch-selected-pressed-state-layer-color: #00a363;
  --mdc-switch-selected-focus-handle-color: #007f3c;
  --mdc-switch-selected-hover-handle-color: #007f3c;
  --mdc-switch-selected-pressed-handle-color: #007f3c;
  --mdc-switch-selected-focus-track-color: #4dc497;
  --mdc-switch-selected-hover-track-color: #4dc497;
  --mdc-switch-selected-pressed-track-color: #4dc497;
  --mdc-switch-selected-track-color: #4dc497;
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #005563;
  --mdc-switch-selected-handle-color: #005563;
  --mdc-switch-selected-hover-state-layer-color: #005563;
  --mdc-switch-selected-pressed-state-layer-color: #005563;
  --mdc-switch-selected-focus-handle-color: #00303c;
  --mdc-switch-selected-hover-handle-color: #00303c;
  --mdc-switch-selected-pressed-handle-color: #00303c;
  --mdc-switch-selected-focus-track-color: #4d8e97;
  --mdc-switch-selected-hover-track-color: #4d8e97;
  --mdc-switch-selected-pressed-track-color: #4d8e97;
  --mdc-switch-selected-track-color: #4d8e97;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #009958;
  --mdc-radio-selected-hover-icon-color: #009958;
  --mdc-radio-selected-icon-color: #009958;
  --mdc-radio-selected-pressed-icon-color: #009958;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #009958;
}
.mat-mdc-radio-button.mat-primary .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #004b58;
  --mdc-radio-selected-hover-icon-color: #004b58;
  --mdc-radio-selected-icon-color: #004b58;
  --mdc-radio-selected-pressed-icon-color: #004b58;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #004b58;
}
.mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #f44336;
}
.mat-mdc-radio-button.mat-warn .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  padding: calc((40px - 20px) / 2);
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  top: calc(-1 * (40px - 20px) / 2);
  left: calc(-1 * (40px - 20px) / 2);
  width: 40px;
  height: 40px;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
  top: calc((40px - 40px) / 2);
  right: calc((40px - 40px) / 2);
  left: calc((40px - 40px) / 2);
  width: 40px;
  height: 40px;
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #009958;
  --mdc-slider-focus-handle-color: #009958;
  --mdc-slider-hover-handle-color: #009958;
  --mdc-slider-active-track-color: #009958;
  --mdc-slider-inactive-track-color: #009958;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #009958;
  --mat-mdc-slider-ripple-color: #009958;
  --mat-mdc-slider-hover-ripple-color: rgba(0, 153, 88, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(0, 153, 88, 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #004b58;
  --mdc-slider-focus-handle-color: #004b58;
  --mdc-slider-hover-handle-color: #004b58;
  --mdc-slider-active-track-color: #004b58;
  --mdc-slider-inactive-track-color: #004b58;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #004b58;
  --mat-mdc-slider-ripple-color: #004b58;
  --mat-mdc-slider-hover-ripple-color: rgba(0, 75, 88, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(0, 75, 88, 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}

.mat-mdc-slider {
  --mdc-slider-label-label-text-font: Roboto;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #009958);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #009958);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-menu-item[disabled],
.mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}

.mat-mdc-menu-item .mat-icon-no-color,
.mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-menu-item:hover:not([disabled]),
.mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-mdc-menu-content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 16px;
}
.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-body1-font-size, 16px);
  line-height: var(--mdc-typography-body1-line-height, 16px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mat-mdc-list-option .mdc-list-item__start, .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #009958;
  --mdc-checkbox-selected-hover-icon-color: #009958;
  --mdc-checkbox-selected-icon-color: #009958;
  --mdc-checkbox-selected-pressed-icon-color: #009958;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-list-option .mdc-list-item__start, .mat-mdc-list-option .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #009958;
  --mdc-radio-selected-hover-icon-color: #009958;
  --mdc-radio-selected-icon-color: #009958;
  --mdc-radio-selected-pressed-icon-color: #009958;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #009958;
}
.mat-mdc-list-option .mdc-list-item__start .mdc-radio--disabled + label, .mat-mdc-list-option .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-list-option.mat-accent .mdc-list-item__start, .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #004b58;
  --mdc-checkbox-selected-hover-icon-color: #004b58;
  --mdc-checkbox-selected-icon-color: #004b58;
  --mdc-checkbox-selected-pressed-icon-color: #004b58;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-list-option.mat-accent .mdc-list-item__start, .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #004b58;
  --mdc-radio-selected-hover-icon-color: #004b58;
  --mdc-radio-selected-icon-color: #004b58;
  --mdc-radio-selected-pressed-icon-color: #004b58;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #004b58;
}
.mat-mdc-list-option.mat-accent .mdc-list-item__start .mdc-radio--disabled + label, .mat-mdc-list-option.mat-accent .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-list-option.mat-warn .mdc-list-item__start, .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-list-option.mat-warn .mdc-list-item__start, .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #f44336;
}
.mat-mdc-list-option.mat-warn .mdc-list-item__start .mdc-radio--disabled + label, .mat-mdc-list-option.mat-warn .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #009958;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #009958;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-font: Roboto;
  --mdc-list-list-item-label-text-line-height: 16px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto;
  --mdc-list-list-item-trailing-supporting-text-line-height: 1.3;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: Roboto;
  letter-spacing: 0.009375em;
}

.mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-mdc-paginator-decrement,
.mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-mdc-paginator-first,
.mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.mat-mdc-paginator-container {
  min-height: 56px;
}

.mat-mdc-paginator {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 1.3);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-paginator .mat-mdc-select-value {
  font-size: 12px;
}

.mat-mdc-tab, .mat-mdc-tab-link {
  background-color: transparent;
}
.mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-link .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.mat-mdc-tab.mat-mdc-tab-disabled .mat-ripple-element, .mat-mdc-tab-link.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.mat-mdc-tab-link.mat-mdc-tab-disabled .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #009958;
}
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #009958);
}
.mdc-tab__ripple::before,
.mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-link .mat-ripple-element {
  background-color: #009958;
}

.mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #004b58;
}
.mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #004b58);
}
.mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #004b58;
}
.mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #f44336;
}
.mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #f44336);
}
.mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #f44336;
}

.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #009958;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #004b58;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #f44336;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}

.mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}

.mat-mdc-tab-header .mdc-tab {
  height: 48px;
}

.mdc-tab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 400);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #009958;
  --mdc-checkbox-selected-hover-icon-color: #009958;
  --mdc-checkbox-selected-icon-color: #009958;
  --mdc-checkbox-selected-pressed-icon-color: #009958;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(0, 153, 88, 0.1);
}
.mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #009958;
}
.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #004b58;
  --mdc-checkbox-selected-hover-icon-color: #004b58;
  --mdc-checkbox-selected-icon-color: #004b58;
  --mdc-checkbox-selected-pressed-icon-color: #004b58;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(0, 75, 88, 0.1);
}
.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #004b58;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(244, 67, 54, 0.1);
}
.mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #f44336;
}

.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox .mdc-checkbox {
  padding: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  margin: calc((var(--mdc-checkbox-touch-target-size, 40px) - 40px) / 2);
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
  top: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  right: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  left: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  width: var(--mdc-checkbox-touch-target-size, 40px);
  height: var(--mdc-checkbox-touch-target-size, 40px);
}

@media all and (-ms-high-contrast: none) {
  .mdc-checkbox .mdc-checkbox__focus-ring {
    display: none;
  }
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #009958;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #004b58;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #009958;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #004b58;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #009958;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #004b58;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #009958;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #004b58;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #009958;
  --mat-mdc-button-ripple-color: rgba(0, 153, 88, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #004b58;
  --mat-mdc-button-ripple-color: rgba(0, 75, 88, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 400);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #009958;
  --mat-mdc-button-ripple-color: rgba(0, 153, 88, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #004b58;
  --mat-mdc-button-ripple-color: rgba(0, 75, 88, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #009958;
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #004b58;
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mat-mdc-fab, .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-unthemed, .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #009958;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #004b58;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab[disabled][disabled], .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 400);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #004b58;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: Roboto;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

.mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__row {
  background-color: inherit;
}

.mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-data-table__row--selected {
  background-color: rgba(0, 153, 88, 0.04);
}

.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__cell,
.mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-data-table__pagination-total,
.mdc-data-table__pagination-rows-per-page-label,
.mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}

[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mat-mdc-table {
  background: white;
}

.mat-mdc-table .mdc-data-table__row {
  height: 52px;
}
.mat-mdc-table .mdc-data-table__pagination {
  min-height: 52px;
}
.mat-mdc-table .mdc-data-table__header-row {
  height: 56px;
}

.mdc-data-table__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__header-cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto));
  font-size: var(--mdc-typography-subtitle2-font-size, 14px);
  line-height: var(--mdc-typography-subtitle2-line-height, 22px);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}

[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #009958;
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #004b58;
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #009958;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #004b58;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-bottom-sheet-container {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto;
  letter-spacing: 0.0178571429em;
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-button-toggle {
  font-family: Roboto;
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(0, 153, 88, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 153, 88, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 153, 88, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #009958;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 153, 88, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 153, 88, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 153, 88, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(0, 75, 88, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 75, 88, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 75, 88, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #004b58;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 75, 88, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 75, 88, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 75, 88, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #009958;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #004b58;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

.mat-calendar {
  font-family: Roboto;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 400;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-expansion-panel-header {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.mat-expansion-panel-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto;
  letter-spacing: 0.0178571429em;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-icon.mat-primary {
  color: #009958;
}
.mat-icon.mat-accent {
  color: #004b58;
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #009958;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #004b58;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 16px;
}

.mat-step-label-selected {
  font-size: 16px;
  font-weight: 400;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #009958;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #004b58;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto;
  letter-spacing: 0.0125em;
  margin: 0;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-tree {
  font-family: Roboto;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.event-week-item {
  border-color: #009958 !important;
}

#login-page h1 {
  color: #009958;
}

.page__head {
  background: #009958;
}

.page__actions {
  background: white;
}

.primary-hover:hover {
  background-color: rgba(0, 153, 88, 0.1) !important;
}

.primary-bg {
  background-color: #009958;
}

.primary-bg-light {
  background-color: rgba(0, 153, 88, 0.1);
}

.accent-bg {
  background-color: #004b58;
}

.accent-bg-light {
  background-color: rgba(0, 75, 88, 0.1);
}

.primary-fg {
  color: white;
}

.primary-color {
  color: #009958 !important;
}

.accent-fg {
  color: white;
}

.accent-color {
  color: #004b58 !important;
}

.light-hint-text {
  color: rgba(0, 0, 0, 0.38);
}

.ng-invalid.ng-touched > .time-input {
  border-color: #f44336;
}

.time-input {
  -webkit-appearance: none;
  padding: 2px 0 1px 2px;
  border: 1.5px solid;
  border-radius: 5px;
  box-shadow: none;
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.time-input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.initials-circle {
  background: #009958;
  color: white;
}

.initials-circle.accent {
  background: #004b58;
  color: white;
}

.initials-circle.warn {
  background: #f44336;
  color: white;
}

blockquote {
  border-color: #009958;
}

.cdk-drag-preview {
  background: white;
}

body.dark-theme .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
body.dark-theme .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark-theme .mat-mdc-option:hover:not(.mdc-list-item--disabled), body.dark-theme .mat-mdc-option:focus:not(.mdc-list-item--disabled), body.dark-theme .mat-mdc-option.mat-mdc-option-active, body.dark-theme .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(255, 255, 255, 0.08);
}
body.dark-theme .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #009958);
}
body.dark-theme .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #004b58);
}
body.dark-theme .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark-theme .mat-pseudo-checkbox-full {
  color: rgba(255, 255, 255, 0.7);
}
body.dark-theme .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #686868;
}
body.dark-theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
body.dark-theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #009958;
}
body.dark-theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
body.dark-theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #009958;
}
body.dark-theme .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
body.dark-theme .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
body.dark-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
body.dark-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #004b58;
}
body.dark-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
body.dark-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #004b58;
}
body.dark-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
body.dark-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
body.dark-theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
body.dark-theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #004b58;
}
body.dark-theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
body.dark-theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #004b58;
}
body.dark-theme .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
body.dark-theme .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
body.dark-theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
body.dark-theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
body.dark-theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
body.dark-theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
body.dark-theme .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
body.dark-theme .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #303030;
}
body.dark-theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
body.dark-theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #686868;
}
body.dark-theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
body.dark-theme .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #686868;
}
body.dark-theme .mat-app-background, body.dark-theme.mat-app-background {
  background-color: #303030;
  color: white;
}
body.dark-theme .mat-elevation-z0, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z1, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z2, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z3, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z4, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z5, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z6, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z7, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z8, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z9, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z10, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z11, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z12, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z13, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z14, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z15, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z16, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z17, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z18, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z19, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z20, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z21, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z22, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z23, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-elevation-z24, body.dark-theme .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

body.dark-theme .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #424242;
}
body.dark-theme .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #595959;
}
body.dark-theme .mat-mdc-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
body.dark-theme .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #009958;
}
body.dark-theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(0, 153, 88, 0.25)'/%3E%3C/svg%3E");
}
body.dark-theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(0, 153, 88, 0.25);
}
body.dark-theme .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #004b58;
}
body.dark-theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(0, 75, 88, 0.25)'/%3E%3C/svg%3E");
}
body.dark-theme .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(0, 75, 88, 0.25);
}
body.dark-theme .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
}
body.dark-theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
}
body.dark-theme .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
}
body.dark-theme .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
body.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.87);
}
@media all {
  body.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
@media all {
  body.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
body.dark-theme .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #009958);
}
body.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
body.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
body.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
body.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
body.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
body.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
body.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
body.dark-theme .mdc-text-field--filled .mdc-text-field__ripple::before,
body.dark-theme .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(255, 255, 255, 0.87));
}
body.dark-theme .mdc-text-field--filled:hover .mdc-text-field__ripple::before, body.dark-theme .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
body.dark-theme .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, body.dark-theme .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
body.dark-theme .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #4a4a4a;
}
body.dark-theme .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.42);
}
body.dark-theme .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.87);
}
body.dark-theme .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #009958);
}
body.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
body.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
body.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.38);
}
body.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
body.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
body.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.87);
}
body.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #009958);
}
[dir=rtl] body.dark-theme .mdc-text-field--outlined.mdc-text-field--with-leading-icon, body.dark-theme .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] body.dark-theme .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, body.dark-theme .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

body.dark-theme .mdc-text-field--outlined .mdc-text-field__ripple::before,
body.dark-theme .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] body.dark-theme .mdc-text-field--with-leading-icon.mdc-text-field--outlined, body.dark-theme .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
body.dark-theme .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 153, 88, 0.87);
}
body.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
body.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
body.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
body.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
body.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.38);
}
@media all {
  body.dark-theme .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
@media all {
  body.dark-theme .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
body.dark-theme .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(255, 255, 255, 0.38);
}
body.dark-theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
body.dark-theme .mdc-text-field--disabled .mdc-text-field-character-counter,
body.dark-theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
body.dark-theme .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
body.dark-theme .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
body.dark-theme .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
body.dark-theme .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
body.dark-theme .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.06);
}
body.dark-theme .mdc-text-field--disabled .mdc-notched-outline__leading,
body.dark-theme .mdc-text-field--disabled .mdc-notched-outline__notch,
body.dark-theme .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.dark-theme .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.dark-theme .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.dark-theme .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.dark-theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.dark-theme .mdc-text-field--disabled .mdc-text-field-character-counter,
  body.dark-theme .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.dark-theme .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.dark-theme .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.dark-theme .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.dark-theme .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.dark-theme .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.dark-theme .mdc-text-field--disabled .mdc-notched-outline__leading,
  body.dark-theme .mdc-text-field--disabled .mdc-notched-outline__notch,
  body.dark-theme .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
body.dark-theme .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #464646;
}
[dir=rtl] body.dark-theme .mdc-text-field--end-aligned .mdc-text-field__input, body.dark-theme .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] body.dark-theme .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), body.dark-theme .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
body.dark-theme .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 0.87);
}
body.dark-theme .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08;
}
body.dark-theme .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.24;
}
body.dark-theme select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
body.dark-theme select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
body.dark-theme .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.54);
}
body.dark-theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(0, 153, 88, 0.87);
}
body.dark-theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(0, 75, 88, 0.87);
}
body.dark-theme .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
body.dark-theme .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.38);
}
body.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #004b58);
}
body.dark-theme .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #004b58);
}
body.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 75, 88, 0.87);
}
body.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
body.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
body.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
body.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
body.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body.dark-theme .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #004b58);
}
body.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(244, 67, 54, 0.87);
}
body.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
body.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
body.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
body.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
body.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body.dark-theme .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}
body.dark-theme .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
body.dark-theme [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
body.dark-theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #424242);
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark-theme .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark-theme .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
body.dark-theme .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
body.dark-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
body.dark-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
body.dark-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
body.dark-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
body.dark-theme .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
body.dark-theme .mdc-list-item--disabled .mdc-list-item__start,
body.dark-theme .mdc-list-item--disabled .mdc-list-item__content,
body.dark-theme .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
body.dark-theme .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark-theme .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark-theme .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark-theme .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark-theme .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark-theme .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark-theme .mdc-list-item--selected .mdc-list-item__primary-text,
body.dark-theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #009958);
}
body.dark-theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
body.dark-theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #009958);
}
body.dark-theme .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark-theme .mdc-list-divider::after {
  border-bottom-color: white;
}
body.dark-theme .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
body.dark-theme .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.87);
}
body.dark-theme .mat-mdc-select-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-theme .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.38);
}
body.dark-theme .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.54);
}
body.dark-theme .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(0, 153, 88, 0.87);
}
body.dark-theme .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(0, 75, 88, 0.87);
}
body.dark-theme .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
body.dark-theme .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(244, 67, 54, 0.87);
}
body.dark-theme .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.38);
}
body.dark-theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #424242);
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark-theme .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark-theme .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
body.dark-theme .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
body.dark-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
body.dark-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
body.dark-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
body.dark-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
body.dark-theme .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
body.dark-theme .mdc-list-item--disabled .mdc-list-item__start,
body.dark-theme .mdc-list-item--disabled .mdc-list-item__content,
body.dark-theme .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
body.dark-theme .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark-theme .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark-theme .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark-theme .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark-theme .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark-theme .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark-theme .mdc-list-item--selected .mdc-list-item__primary-text,
body.dark-theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #009958);
}
body.dark-theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
body.dark-theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #009958);
}
body.dark-theme .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark-theme .mdc-list-divider::after {
  border-bottom-color: white;
}
body.dark-theme .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
body.dark-theme .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #424242;
  --mdc-dialog-with-divider-divider-color: rgba(255, 255, 255, 0.12);
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
body.dark-theme .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #595959;
  --mdc-chip-elevated-disabled-container-color: #595959;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
}
body.dark-theme .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, body.dark-theme .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #009958;
  --mdc-chip-elevated-disabled-container-color: #009958;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
body.dark-theme .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, body.dark-theme .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #004b58;
  --mdc-chip-elevated-disabled-container-color: #004b58;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
body.dark-theme .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, body.dark-theme .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
body.dark-theme .mat-mdc-chip-focus-overlay {
  background: white;
}
body.dark-theme .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-unselected-icon-color: #212121;
}
body.dark-theme .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark-theme .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(255, 255, 255, 0.5);
}
body.dark-theme .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #4dc497;
  --mdc-switch-selected-handle-color: #4dc497;
  --mdc-switch-selected-hover-state-layer-color: #4dc497;
  --mdc-switch-selected-pressed-state-layer-color: #4dc497;
  --mdc-switch-selected-focus-handle-color: #80d5b5;
  --mdc-switch-selected-hover-handle-color: #80d5b5;
  --mdc-switch-selected-pressed-handle-color: #80d5b5;
  --mdc-switch-selected-focus-track-color: #00a363;
  --mdc-switch-selected-hover-track-color: #00a363;
  --mdc-switch-selected-pressed-track-color: #00a363;
  --mdc-switch-selected-track-color: #00a363;
}
body.dark-theme .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #4d8e97;
  --mdc-switch-selected-handle-color: #4d8e97;
  --mdc-switch-selected-hover-state-layer-color: #4d8e97;
  --mdc-switch-selected-pressed-state-layer-color: #4d8e97;
  --mdc-switch-selected-focus-handle-color: #80aeb5;
  --mdc-switch-selected-hover-handle-color: #80aeb5;
  --mdc-switch-selected-pressed-handle-color: #80aeb5;
  --mdc-switch-selected-focus-track-color: #005563;
  --mdc-switch-selected-hover-track-color: #005563;
  --mdc-switch-selected-pressed-track-color: #005563;
  --mdc-switch-selected-track-color: #005563;
}
body.dark-theme .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e57373;
  --mdc-switch-selected-handle-color: #e57373;
  --mdc-switch-selected-hover-state-layer-color: #e57373;
  --mdc-switch-selected-pressed-state-layer-color: #e57373;
  --mdc-switch-selected-focus-handle-color: #ef9a9a;
  --mdc-switch-selected-hover-handle-color: #ef9a9a;
  --mdc-switch-selected-pressed-handle-color: #ef9a9a;
  --mdc-switch-selected-focus-track-color: #e53935;
  --mdc-switch-selected-hover-track-color: #e53935;
  --mdc-switch-selected-pressed-track-color: #e53935;
  --mdc-switch-selected-track-color: #e53935;
}
body.dark-theme .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark-theme .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #009958;
  --mdc-radio-selected-hover-icon-color: #009958;
  --mdc-radio-selected-icon-color: #009958;
  --mdc-radio-selected-pressed-icon-color: #009958;
  --mat-mdc-radio-ripple-color: #fff;
  --mat-mdc-radio-checked-ripple-color: #009958;
}
body.dark-theme .mat-mdc-radio-button.mat-primary .mdc-radio--disabled + label {
  color: rgba(255, 255, 255, 0.5);
}
body.dark-theme .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #004b58;
  --mdc-radio-selected-hover-icon-color: #004b58;
  --mdc-radio-selected-icon-color: #004b58;
  --mdc-radio-selected-pressed-icon-color: #004b58;
  --mat-mdc-radio-ripple-color: #fff;
  --mat-mdc-radio-checked-ripple-color: #004b58;
}
body.dark-theme .mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label {
  color: rgba(255, 255, 255, 0.5);
}
body.dark-theme .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-mdc-radio-ripple-color: #fff;
  --mat-mdc-radio-checked-ripple-color: #f44336;
}
body.dark-theme .mat-mdc-radio-button.mat-warn .mdc-radio--disabled + label {
  color: rgba(255, 255, 255, 0.5);
}
body.dark-theme .mat-mdc-slider {
  --mdc-slider-label-container-color: white;
  --mdc-slider-label-label-text-color: black;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-mdc-slider-value-indicator-opacity: 0.9;
}
body.dark-theme .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #009958;
  --mdc-slider-focus-handle-color: #009958;
  --mdc-slider-hover-handle-color: #009958;
  --mdc-slider-active-track-color: #009958;
  --mdc-slider-inactive-track-color: #009958;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #009958;
  --mat-mdc-slider-ripple-color: #009958;
  --mat-mdc-slider-hover-ripple-color: rgba(0, 153, 88, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(0, 153, 88, 0.2);
}
body.dark-theme .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #004b58;
  --mdc-slider-focus-handle-color: #004b58;
  --mdc-slider-hover-handle-color: #004b58;
  --mdc-slider-active-track-color: #004b58;
  --mdc-slider-inactive-track-color: #004b58;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #004b58;
  --mat-mdc-slider-ripple-color: #004b58;
  --mat-mdc-slider-hover-ripple-color: rgba(0, 75, 88, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(0, 75, 88, 0.2);
}
body.dark-theme .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}
body.dark-theme .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #424242);
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark-theme .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark-theme .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
body.dark-theme .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
body.dark-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
body.dark-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
body.dark-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
body.dark-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
body.dark-theme .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
body.dark-theme .mdc-list-item--disabled .mdc-list-item__start,
body.dark-theme .mdc-list-item--disabled .mdc-list-item__content,
body.dark-theme .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
body.dark-theme .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark-theme .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark-theme .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark-theme .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark-theme .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark-theme .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark-theme .mdc-list-item--selected .mdc-list-item__primary-text,
body.dark-theme .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #009958);
}
body.dark-theme .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
body.dark-theme .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #009958);
}
body.dark-theme .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark-theme .mdc-list-divider::after {
  border-bottom-color: white;
}
body.dark-theme .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
body.dark-theme .mat-mdc-menu-item[disabled],
body.dark-theme .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
body.dark-theme .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(255, 255, 255, 0.5));
}
body.dark-theme .mat-mdc-menu-item .mat-icon-no-color,
body.dark-theme .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark-theme .mat-mdc-menu-item:hover:not([disabled]),
body.dark-theme .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
body.dark-theme .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
body.dark-theme .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.08);
}
body.dark-theme .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: rgba(255, 255, 255, 0.7);
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
}
body.dark-theme .mat-mdc-list-option .mdc-list-item__start, body.dark-theme .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #009958;
  --mdc-checkbox-selected-hover-icon-color: #009958;
  --mdc-checkbox-selected-icon-color: #009958;
  --mdc-checkbox-selected-pressed-icon-color: #009958;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
body.dark-theme .mat-mdc-list-option .mdc-list-item__start, body.dark-theme .mat-mdc-list-option .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #009958;
  --mdc-radio-selected-hover-icon-color: #009958;
  --mdc-radio-selected-icon-color: #009958;
  --mdc-radio-selected-pressed-icon-color: #009958;
  --mat-mdc-radio-ripple-color: #fff;
  --mat-mdc-radio-checked-ripple-color: #009958;
}
body.dark-theme .mat-mdc-list-option .mdc-list-item__start .mdc-radio--disabled + label, body.dark-theme .mat-mdc-list-option .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(255, 255, 255, 0.5);
}
body.dark-theme .mat-mdc-list-option.mat-accent .mdc-list-item__start, body.dark-theme .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #004b58;
  --mdc-checkbox-selected-hover-icon-color: #004b58;
  --mdc-checkbox-selected-icon-color: #004b58;
  --mdc-checkbox-selected-pressed-icon-color: #004b58;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
body.dark-theme .mat-mdc-list-option.mat-accent .mdc-list-item__start, body.dark-theme .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #004b58;
  --mdc-radio-selected-hover-icon-color: #004b58;
  --mdc-radio-selected-icon-color: #004b58;
  --mdc-radio-selected-pressed-icon-color: #004b58;
  --mat-mdc-radio-ripple-color: #fff;
  --mat-mdc-radio-checked-ripple-color: #004b58;
}
body.dark-theme .mat-mdc-list-option.mat-accent .mdc-list-item__start .mdc-radio--disabled + label, body.dark-theme .mat-mdc-list-option.mat-accent .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(255, 255, 255, 0.5);
}
body.dark-theme .mat-mdc-list-option.mat-warn .mdc-list-item__start, body.dark-theme .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
body.dark-theme .mat-mdc-list-option.mat-warn .mdc-list-item__start, body.dark-theme .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-mdc-radio-ripple-color: #fff;
  --mat-mdc-radio-checked-ripple-color: #f44336;
}
body.dark-theme .mat-mdc-list-option.mat-warn .mdc-list-item__start .mdc-radio--disabled + label, body.dark-theme .mat-mdc-list-option.mat-warn .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(255, 255, 255, 0.5);
}
body.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
body.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #009958;
}
body.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
body.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #009958;
}
body.dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
body.dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
body.dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
body.dark-theme .mat-mdc-paginator {
  background: #424242;
  color: rgba(255, 255, 255, 0.87);
}
body.dark-theme .mat-mdc-paginator-icon {
  fill: rgba(255, 255, 255, 0.54);
}
body.dark-theme .mat-mdc-paginator-decrement,
body.dark-theme .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
  border-right: 2px solid rgba(255, 255, 255, 0.54);
}
body.dark-theme .mat-mdc-paginator-first,
body.dark-theme .mat-mdc-paginator-last {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
}
body.dark-theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
body.dark-theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
body.dark-theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
body.dark-theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(255, 255, 255, 0.12);
}
body.dark-theme .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(255, 255, 255, 0.12);
}
body.dark-theme .mat-mdc-tab, body.dark-theme .mat-mdc-tab-link {
  background-color: transparent;
}
body.dark-theme .mat-mdc-tab .mdc-tab__text-label, body.dark-theme .mat-mdc-tab-link .mdc-tab__text-label {
  color: rgba(255, 255, 255, 0.6);
}
body.dark-theme .mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple::before,
body.dark-theme .mat-mdc-tab.mat-mdc-tab-disabled .mat-ripple-element, body.dark-theme .mat-mdc-tab-link.mat-mdc-tab-disabled .mdc-tab__ripple::before,
body.dark-theme .mat-mdc-tab-link.mat-mdc-tab-disabled .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.5);
}
body.dark-theme .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, body.dark-theme .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #009958;
}
body.dark-theme .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, body.dark-theme .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #009958);
}
body.dark-theme .mdc-tab__ripple::before,
body.dark-theme .mat-mdc-tab .mat-ripple-element,
body.dark-theme .mat-mdc-tab-header-pagination .mat-ripple-element,
body.dark-theme .mat-mdc-tab-link .mat-ripple-element {
  background-color: #009958;
}
body.dark-theme .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, body.dark-theme .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, body.dark-theme .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, body.dark-theme .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #004b58;
}
body.dark-theme .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, body.dark-theme .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, body.dark-theme .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, body.dark-theme .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #004b58);
}
body.dark-theme .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
body.dark-theme .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
body.dark-theme .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
body.dark-theme .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, body.dark-theme .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
body.dark-theme .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
body.dark-theme .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
body.dark-theme .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #004b58;
}
body.dark-theme .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, body.dark-theme .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, body.dark-theme .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, body.dark-theme .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #f44336;
}
body.dark-theme .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, body.dark-theme .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, body.dark-theme .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, body.dark-theme .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #f44336);
}
body.dark-theme .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
body.dark-theme .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
body.dark-theme .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
body.dark-theme .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, body.dark-theme .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
body.dark-theme .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
body.dark-theme .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
body.dark-theme .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #f44336;
}
body.dark-theme .mat-mdc-tab-group.mat-background-primary, body.dark-theme .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #009958;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
body.dark-theme .mat-mdc-tab-group.mat-background-accent, body.dark-theme .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #004b58;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
body.dark-theme .mat-mdc-tab-group.mat-background-warn, body.dark-theme .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #f44336;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
body.dark-theme .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #fff);
}
body.dark-theme .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark-theme .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
body.dark-theme .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #fff;
}
body.dark-theme .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #009958;
  --mdc-checkbox-selected-hover-icon-color: #009958;
  --mdc-checkbox-selected-icon-color: #009958;
  --mdc-checkbox-selected-pressed-icon-color: #009958;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
body.dark-theme .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(0, 153, 88, 0.1);
}
body.dark-theme .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #009958;
}
body.dark-theme .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #004b58;
  --mdc-checkbox-selected-hover-icon-color: #004b58;
  --mdc-checkbox-selected-icon-color: #004b58;
  --mdc-checkbox-selected-pressed-icon-color: #004b58;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
body.dark-theme .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(0, 75, 88, 0.1);
}
body.dark-theme .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #004b58;
}
body.dark-theme .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
body.dark-theme .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(244, 67, 54, 0.1);
}
body.dark-theme .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #f44336;
}
body.dark-theme .mat-mdc-checkbox-disabled label {
  color: rgba(255, 255, 255, 0.5);
}
body.dark-theme .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #fff;
}
body.dark-theme .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #009958;
}
body.dark-theme .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #004b58;
}
body.dark-theme .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
body.dark-theme .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-label-text-color: rgba(255, 255, 255, 0.5);
}
body.dark-theme .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #424242;
  --mdc-filled-button-label-text-color: #fff;
}
body.dark-theme .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #009958;
  --mdc-filled-button-label-text-color: #fff;
}
body.dark-theme .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #004b58;
  --mdc-filled-button-label-text-color: #fff;
}
body.dark-theme .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
body.dark-theme .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.5);
}
body.dark-theme .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #424242;
  --mdc-protected-button-label-text-color: #fff;
}
body.dark-theme .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #009958;
  --mdc-protected-button-label-text-color: #fff;
}
body.dark-theme .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #004b58;
  --mdc-protected-button-label-text-color: #fff;
}
body.dark-theme .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
body.dark-theme .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation: 0;
}
body.dark-theme .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
body.dark-theme .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
body.dark-theme .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #009958;
}
body.dark-theme .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #004b58;
}
body.dark-theme .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
body.dark-theme .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
body.dark-theme .mat-mdc-button, body.dark-theme .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.dark-theme .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, body.dark-theme .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
body.dark-theme .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, body.dark-theme .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, body.dark-theme .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, body.dark-theme .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
body.dark-theme .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, body.dark-theme .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
body.dark-theme .mat-mdc-button.mat-primary, body.dark-theme .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #009958;
  --mat-mdc-button-ripple-color: rgba(0, 153, 88, 0.1);
}
body.dark-theme .mat-mdc-button.mat-accent, body.dark-theme .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #004b58;
  --mat-mdc-button-ripple-color: rgba(0, 75, 88, 0.1);
}
body.dark-theme .mat-mdc-button.mat-warn, body.dark-theme .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
body.dark-theme .mat-mdc-raised-button, body.dark-theme .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.dark-theme .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, body.dark-theme .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
body.dark-theme .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, body.dark-theme .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, body.dark-theme .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, body.dark-theme .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
body.dark-theme .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, body.dark-theme .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
body.dark-theme .mat-mdc-raised-button.mat-primary, body.dark-theme .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.dark-theme .mat-mdc-raised-button.mat-accent, body.dark-theme .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.dark-theme .mat-mdc-raised-button.mat-warn, body.dark-theme .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.dark-theme .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.dark-theme .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
body.dark-theme .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, body.dark-theme .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
body.dark-theme .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
body.dark-theme .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #009958;
  --mat-mdc-button-ripple-color: rgba(0, 153, 88, 0.1);
}
body.dark-theme .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #004b58;
  --mat-mdc-button-ripple-color: rgba(0, 75, 88, 0.1);
}
body.dark-theme .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
body.dark-theme .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #009958;
}
body.dark-theme .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #004b58;
}
body.dark-theme .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
}
body.dark-theme .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
body.dark-theme .mat-mdc-fab, body.dark-theme .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.dark-theme .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, body.dark-theme .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
body.dark-theme .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, body.dark-theme .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, body.dark-theme .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, body.dark-theme .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
body.dark-theme .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, body.dark-theme .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
body.dark-theme .mat-mdc-fab.mat-primary, body.dark-theme .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.dark-theme .mat-mdc-fab.mat-accent, body.dark-theme .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.dark-theme .mat-mdc-fab.mat-warn, body.dark-theme .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.dark-theme .mat-mdc-fab.mat-unthemed, body.dark-theme .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #424242;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
body.dark-theme .mat-mdc-fab.mat-primary, body.dark-theme .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #009958;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
body.dark-theme .mat-mdc-fab.mat-accent, body.dark-theme .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #004b58;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
body.dark-theme .mat-mdc-fab.mat-warn, body.dark-theme .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
body.dark-theme .mat-mdc-fab[disabled][disabled], body.dark-theme .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(255, 255, 255, 0.12);
  --mdc-fab-icon-color: rgba(255, 255, 255, 0.5);
  --mat-mdc-fab-color: rgba(255, 255, 255, 0.5);
}
body.dark-theme .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  --mdc-snackbar-container-color: #d9d9d9;
  --mdc-snackbar-supporting-text-color: rgba(66, 66, 66, 0.87);
}
body.dark-theme .mdc-data-table {
  background-color: var(--mdc-theme-surface, #424242);
  border-color: rgba(255, 255, 255, 0.12);
}
body.dark-theme .mdc-data-table__row {
  background-color: inherit;
}
body.dark-theme .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #424242);
}
body.dark-theme .mdc-data-table__row--selected {
  background-color: rgba(0, 153, 88, 0.04);
}
body.dark-theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
body.dark-theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
body.dark-theme .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.12);
}
body.dark-theme .mdc-data-table__cell,
body.dark-theme .mdc-data-table__header-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
body.dark-theme .mdc-data-table__pagination {
  border-top-color: rgba(255, 255, 255, 0.12);
}
body.dark-theme .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(255, 255, 255, 0.04);
}
body.dark-theme .mdc-data-table__header-cell {
  color: rgba(255, 255, 255, 0.87);
}
body.dark-theme .mdc-data-table__pagination-total,
body.dark-theme .mdc-data-table__pagination-rows-per-page-label,
body.dark-theme .mdc-data-table__cell {
  color: rgba(255, 255, 255, 0.87);
}
[dir=rtl] body.dark-theme .mdc-data-table__pagination-button .mdc-button__icon, body.dark-theme .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

body.dark-theme .mat-mdc-table {
  background: #424242;
}
body.dark-theme .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #009958;
}
body.dark-theme .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #004b58;
}
body.dark-theme .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}
body.dark-theme .mat-badge-content {
  color: white;
  background: #009958;
}
.cdk-high-contrast-active body.dark-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

body.dark-theme .mat-badge-accent .mat-badge-content {
  background: #004b58;
  color: white;
}
body.dark-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
body.dark-theme .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
body.dark-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
body.dark-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
body.dark-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
body.dark-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
body.dark-theme .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
body.dark-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
body.dark-theme .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
body.dark-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
body.dark-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
body.dark-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
body.dark-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
body.dark-theme .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
body.dark-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
body.dark-theme .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
body.dark-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
body.dark-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
body.dark-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
body.dark-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
body.dark-theme .mat-calendar-arrow {
  fill: white;
}
body.dark-theme .mat-datepicker-toggle,
body.dark-theme .mat-datepicker-content .mat-calendar-next-button,
body.dark-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
body.dark-theme .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
body.dark-theme .mat-calendar-table-header,
body.dark-theme .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
body.dark-theme .mat-calendar-body-cell-content,
body.dark-theme .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
body.dark-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
body.dark-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
body.dark-theme .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
body.dark-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
body.dark-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
body.dark-theme .mat-calendar-body-in-range::before {
  background: rgba(0, 153, 88, 0.2);
}
body.dark-theme .mat-calendar-body-comparison-identical,
body.dark-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
body.dark-theme .mat-calendar-body-comparison-bridge-start::before,
body.dark-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 153, 88, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.dark-theme .mat-calendar-body-comparison-bridge-end::before,
body.dark-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 153, 88, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.dark-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body.dark-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
body.dark-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body.dark-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
body.dark-theme .mat-calendar-body-selected {
  background-color: #009958;
  color: white;
}
body.dark-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 153, 88, 0.4);
}
body.dark-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
body.dark-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body.dark-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 153, 88, 0.3);
}
@media (hover: hover) {
  body.dark-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 153, 88, 0.3);
  }
}
body.dark-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
body.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(0, 75, 88, 0.2);
}
body.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
body.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
body.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
body.dark-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 75, 88, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
body.dark-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 75, 88, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
body.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
body.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #004b58;
  color: white;
}
body.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 75, 88, 0.4);
}
body.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
body.dark-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body.dark-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 75, 88, 0.3);
}
@media (hover: hover) {
  body.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 75, 88, 0.3);
  }
}
body.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
body.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
body.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
body.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
body.dark-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
body.dark-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
body.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
body.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
body.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
body.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
body.dark-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body.dark-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  body.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
body.dark-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-datepicker-toggle-active {
  color: #009958;
}
body.dark-theme .mat-datepicker-toggle-active.mat-accent {
  color: #004b58;
}
body.dark-theme .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
body.dark-theme .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
body.dark-theme .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
body.dark-theme .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
body.dark-theme .mat-expansion-panel {
  background: #424242;
  color: white;
}
body.dark-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
body.dark-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), body.dark-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), body.dark-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  body.dark-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
body.dark-theme .mat-expansion-panel-header-title {
  color: white;
}
body.dark-theme .mat-expansion-panel-header-description,
body.dark-theme .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
body.dark-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
body.dark-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
body.dark-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
body.dark-theme .mat-icon.mat-primary {
  color: #009958;
}
body.dark-theme .mat-icon.mat-accent {
  color: #004b58;
}
body.dark-theme .mat-icon.mat-warn {
  color: #f44336;
}
body.dark-theme .mat-drawer-container {
  background-color: #303030;
  color: white;
}
body.dark-theme .mat-drawer {
  background-color: #424242;
  color: white;
}
body.dark-theme .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
body.dark-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
body.dark-theme .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
body.dark-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
body.dark-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
body.dark-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
body.dark-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
body.dark-theme .mat-step-header.cdk-keyboard-focused, body.dark-theme .mat-step-header.cdk-program-focused, body.dark-theme .mat-step-header:hover:not([aria-disabled]), body.dark-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
body.dark-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  body.dark-theme .mat-step-header:hover {
    background: none;
  }
}
body.dark-theme .mat-step-header .mat-step-label,
body.dark-theme .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
body.dark-theme .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
body.dark-theme .mat-step-header .mat-step-icon-selected,
body.dark-theme .mat-step-header .mat-step-icon-state-done,
body.dark-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #009958;
  color: white;
}
body.dark-theme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
body.dark-theme .mat-step-header.mat-accent .mat-step-icon-selected,
body.dark-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
body.dark-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #004b58;
  color: white;
}
body.dark-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
body.dark-theme .mat-step-header.mat-warn .mat-step-icon-selected,
body.dark-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
body.dark-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
body.dark-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
body.dark-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
body.dark-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
body.dark-theme .mat-stepper-horizontal, body.dark-theme .mat-stepper-vertical {
  background-color: #424242;
}
body.dark-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
body.dark-theme .mat-horizontal-stepper-header::before,
body.dark-theme .mat-horizontal-stepper-header::after,
body.dark-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
body.dark-theme .mat-sort-header-arrow {
  color: #c6c6c6;
}
body.dark-theme .mat-toolbar {
  background: #212121;
  color: white;
}
body.dark-theme .mat-toolbar.mat-primary {
  background: #009958;
  color: white;
}
body.dark-theme .mat-toolbar.mat-accent {
  background: #004b58;
  color: white;
}
body.dark-theme .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
body.dark-theme .mat-toolbar .mat-form-field-underline,
body.dark-theme .mat-toolbar .mat-form-field-ripple,
body.dark-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
body.dark-theme .mat-toolbar .mat-form-field-label,
body.dark-theme .mat-toolbar .mat-focused .mat-form-field-label,
body.dark-theme .mat-toolbar .mat-select-value,
body.dark-theme .mat-toolbar .mat-select-arrow,
body.dark-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
body.dark-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
body.dark-theme .mat-tree {
  background: #424242;
}
body.dark-theme .mat-tree-node,
body.dark-theme .mat-nested-tree-node {
  color: white;
}
body.dark-theme .event-week-item {
  border-color: #009958 !important;
}
body.dark-theme #login-page h1 {
  color: white;
}
body.dark-theme .page__head {
  background: #009958;
}
body.dark-theme .page__actions {
  background: #424242;
}
body.dark-theme .primary-hover:hover {
  background-color: rgba(0, 153, 88, 0.1) !important;
}
body.dark-theme .primary-bg {
  background-color: #009958;
}
body.dark-theme .primary-bg-light {
  background-color: rgba(0, 153, 88, 0.1);
}
body.dark-theme .accent-bg {
  background-color: #004b58;
}
body.dark-theme .accent-bg-light {
  background-color: rgba(0, 75, 88, 0.1);
}
body.dark-theme .primary-fg {
  color: white;
}
body.dark-theme .primary-color {
  color: #009958 !important;
}
body.dark-theme .accent-fg {
  color: white;
}
body.dark-theme .accent-color {
  color: #004b58 !important;
}
body.dark-theme .light-hint-text {
  color: rgba(255, 255, 255, 0.5);
}
body.dark-theme .ng-invalid.ng-touched > .time-input {
  border-color: #f44336;
}
body.dark-theme .time-input {
  -webkit-appearance: none;
  padding: 2px 0 1px 2px;
  border: 1.5px solid;
  border-radius: 5px;
  box-shadow: none;
  background: transparent;
  color: white;
}
body.dark-theme .time-input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
body.dark-theme .initials-circle {
  background: #009958;
  color: white;
}
body.dark-theme .initials-circle.accent {
  background: #004b58;
  color: white;
}
body.dark-theme .initials-circle.warn {
  background: #f44336;
  color: white;
}
body.dark-theme blockquote {
  border-color: #009958;
}
body.dark-theme .cdk-drag-preview {
  background: #424242;
  color: white;
}
body.dark-theme .mat-toolbar {
  background: #212121 !important;
  color: white;
}

button.active {
  color: #004b58 !important;
}

button.pulse {
  box-shadow: 0 0 0 #004b58;
  animation: pulse 2s infinite;
}
button.pulse:hover {
  animation: none;
}

.mat-mdc-nav-list .mat-mdc-list-item .lnr {
  margin-right: 10px;
}
.mat-mdc-nav-list .mat-mdc-list-item .mat-mdc-list-item-unscoped-content {
  height: 100%;
  width: 100%;
}
.mat-mdc-nav-list .mat-mdc-list-item .mdc-list-item__content {
  height: 100%;
  width: 100%;
  display: inline-flex;
  align-items: center;
}
.mat-mdc-nav-list .mat-mdc-list-item.active {
  background-color: rgba(0, 153, 88, 0.2);
}
.mat-mdc-nav-list .mat-mdc-list-item.active:hover, .mat-mdc-nav-list .mat-mdc-list-item.active:focus {
  background-color: rgba(0, 153, 88, 0.3) !important;
}
.mat-mdc-nav-list .mat-mdc-list-item:hover, .mat-mdc-nav-list .mat-mdc-list-item:focus {
  background-color: rgba(0, 153, 88, 0.1) !important;
}

.mat-table.app-background {
  background-color: #fafafa !important;
}

tremaze-expanding-icon-text-input {
  color: white;
}
tremaze-expanding-icon-text-input input {
  background-color: rgba(0, 75, 88, 0.3);
  color: black;
}
tremaze-expanding-icon-text-input span {
  background-color: #004b58;
}

.badge {
  background-color: #004b58;
  color: white;
}
.badge--primary {
  background-color: #009958;
  color: white;
}

.hint--primary {
  background-color: rgba(0, 153, 88, 0.075);
  border-color: #009958 !important;
}
.hint--accent {
  background-color: rgba(0, 75, 88, 0.075);
  border-color: #004b58 !important;
}
.hint--warn {
  background-color: rgba(244, 67, 54, 0.075);
  border-color: #f44336 !important;
}

.mat-mdc-raised-button:not(:disabled).mat-primary, .mat-mdc-unelevated-button.mat-primary, .mat-mdc-raised-button.mat-primary, .mat-mdc-mini-fab.mat-primary, .mat-mdc-fab.mat-primary {
  color: #ffffff !important;
}
.mat-mdc-raised-button:not(:disabled).mat-accent, .mat-mdc-unelevated-button.mat-accent, .mat-mdc-raised-button.mat-accent, .mat-mdc-mini-fab.mat-accent, .mat-mdc-fab.mat-accent {
  color: #ffffff !important;
}

.mdc-button {
  border-radius: 12px !important;
  font-weight: bold;
}
.mdc-button--raised {
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 12px !important;
}

.mat-mdc-checkbox.mat-accent svg {
  color: #ffffff !important;
}
.mat-mdc-checkbox.mat-primary svg {
  color: #ffffff !important;
}

a {
  color: #26b781;
  transition: color 0.2s ease-in-out;
}
a:hover {
  color: #00a363;
}
.default-table thead {
  background-color: white;
  border-bottom: 20px solid transparent;
}
tbody > tr:not(.default-table__nostyle):hover {
  background-color: rgba(224, 245, 237, 0.3) !important;
}
.default-table:not(.even) tbody tr:nth-child(odd):not(.default-table__nostyle), .default-table.even tbody tr:nth-child(even):not(.default-table__nostyle) {
  background-color: rgba(224, 245, 237, 0.5);
}
.default-table:not(.even) tbody tr:nth-child(odd):not(.default-table__nostyle):hover, .default-table.even tbody tr:nth-child(even):not(.default-table__nostyle):hover {
  background-color: rgba(224, 245, 237, 0.8) !important;
}