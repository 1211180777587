mat-card {
  display: flex !important;
  flex-direction: column !important;
  box-sizing: border-box !important;
  border-radius: 25px !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 25px !important;

  .mat-mdc-card-header {
    padding-bottom: 8px !important;
  }

  mat-card-content {
    flex: 1 !important;
    overflow: hidden;
  }

  &.enableScroll {
    mat-card-content {
      overflow: auto;
    }
  }
}
