@use '@angular/material' as mat;
@use 'sass:map';

@mixin tremaze-mat-nav-list-theme($theme) {
  $primary: map.get($theme, primary);
  .mat-mdc-nav-list {
    .mat-mdc-list-item {

      .lnr {
        margin-right: 10px;
      }

      .mat-mdc-list-item-unscoped-content {
        height: 100%;
        width: 100%;
      }

      .mdc-list-item__content {
        height: 100%;
        width: 100%;
        display: inline-flex;
        align-items: center;
      }

      &.active {
        background-color: mat.get-color-from-palette($primary, 0.2);

        &:hover,
        &:focus {
          background-color: mat.get-color-from-palette($primary, 0.3) !important;
        }
      }

      &:hover,
      &:focus {
        background-color: mat.get-color-from-palette($primary, 0.1) !important;
      }
    }
  }
}
