@use 'sass:map';
@use '@angular/material' as mat;

.mat-mdc-table {
  .mat-column-actions {
    width: 250px;
    overflow: auto;
    white-space: nowrap;
  }

  &.dense-actions {
    .mat-column-actions {
      max-width: 120px;
      width: 120px;
    }
  }

  .mat-cell {
    &:not(:first-child) {
      padding-left: 5px;
    }

    &:not(:last-child) {
      padding-right: 5px;
    }
  }

  .mat-header-cell {
    padding-left: 7px;
  }
}

@mixin tremaze-mat-table-theme($theme) {
  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  .mat-table.app-background {
    background-color: mat.get-color-from-palette($background, background) !important;
  }
}
