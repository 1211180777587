@use '@angular/material' as mat;
@use 'sass:map';

@mixin tremaze-mat-checkbox-theme($light-theme) {

  $primary-palette: mat.get-color-from-palette($light-theme, primary);
  $primary-contrast: map.get($primary-palette, contrast);

  $primary-contrast-500: map.get($primary-contrast, 500);

  $accent-palette: mat.get-color-from-palette($light-theme, accent);
  $accent-contrast: map.get($accent-palette, contrast);

  $accent-contrast-500: map.get($accent-contrast, 500);

  .mat-mdc-checkbox {
    &.mat-accent {

      svg {
        color: $accent-contrast-500 !important;
      }

    }

    &.mat-primary {

      svg {
        color: $primary-contrast-500 !important;
      }

    }


  }

}
