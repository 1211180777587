@import "../elements/elements.scrollbar";

.transparent-backdrop {
  background-color: transparent !important;
}

.dialog-no-padding {
  .mat-dialog-container {
    padding: 0;
  }
}

.relative-dialog {
  .mat-dialog-container {
    position: relative;
  }
}

.mat-dialog-container {
  max-height: 100vh !important;
  overflow: hidden !important;
  display: flex !important;
  flex-direction: column !important;
  border-radius: 25px !important;

  > *:first-child {
    display: contents;
  }
}

.mat-dialog-content {
  @extend .custom-scrollbar !optional;
  @media (max-width: 980px) {
    max-height: unset !important;
  }

  .mat-tab-body-content {
    overflow: auto !important;
  }

  .mat-stepper-horizontal {
    max-height: 610px;
    display: flex;
    flex-direction: column;

    .mat-horizontal-content-container {
      overflow-y: auto;
      @extend .custom-scrollbar;
    }
  }
}

.cdk-overlay-pane {
  @media (max-width: 980px) {
    max-width: 100vw !important;
  }
}
