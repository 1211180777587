@use '@angular/material' as mat;
@use 'sass:map';


.badge {
  border-radius: 7px;
  text-align: center;
  font-size: 12px;
  padding: 1px 3px;
  min-width: 1.15em;
  display: inline-block;
  line-height: 1.5em;
}

@mixin tremaze-badge-theme($theme) {
  $accent: map.get($theme, accent);
  $primary: map.get($theme, primary);
  .badge {
    background-color: mat.get-color-from-palette($accent);
    color: mat.get-color-from-palette($accent, default-contrast);

    &--primary {
      background-color: mat.get-color-from-palette($primary);
      color: mat.get-color-from-palette($primary, default-contrast);
    }
  }

}

