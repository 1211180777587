.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #a8a8a8;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: 0 0;
    padding-right: 3px;
    padding-bottom: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #a8a8a8;
    border-radius: 2px;
    margin-right: 3px;
    margin-bottom: 3px;
  }
}
