@use '@angular/material' as mat;
@use 'sass:map';

blockquote {
  border-left: 3px solid;
  margin-left: 10px;
  padding: 10px 0 10px 10px;
  border-color: lightgray;

  > *:first-child {
    margin: 0;
  }
}

@mixin blockquote-theme($config-or-theme) {
  $primary: map.get($config-or-theme, primary);
  blockquote {
    border-color: mat.get-color-from-palette($primary);
  }
}
