@use '@angular/material' as mat;
@use 'sass:map';
@import 'libs/shared/ui/inputs/time-input/src/lib/time-input-theme';
@import 'libs/shared/ui/initials-circle/src/lib/initials-circle.theme.ts';


@mixin page-mixin($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  .page__head {
    background: mat.get-color-from-palette($primary);
  }
  .page__actions {
    background: mat.get-color-from-palette($background, card);
  }
  .primary-hover {
    &:hover {
      background-color: mat.get-color-from-palette($primary, 0.1) !important;
    }
  }
  .primary-bg {
    background-color: mat.get-color-from-palette($primary);
  }
  .primary-bg-light {
    background-color: mat.get-color-from-palette($primary, 0.1);
  }
  .accent-bg {
    background-color: mat.get-color-from-palette($accent);
  }
  .accent-bg-light {
    background-color: mat.get-color-from-palette($accent, 0.1);
  }
  .primary-fg {
    color: mat.get-color-from-palette($primary, default-contrast);
  }
  .primary-color {
    color: mat.get-color-from-palette($primary) !important;
  }
  .accent-fg {
    color: mat.get-color-from-palette($accent, default-contrast);
  }
  .accent-color {
    color: mat.get-color-from-palette($accent) !important;
  }
  .light-hint-text {
    color: mat.get-color-from-palette($foreground, hint-text);
  }

}


@mixin includeTheme($light, $dark) {

  $color-config: mat.get-color-config($light);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  @include mat.core-theme($light);
  @include mat.all-component-themes($light);
  @include event-week-schedule-item-mixin($light);
  @include login-page-mixin(mat.get-color-from-palette($primary-palette));
  @include page-mixin($light);
  @include time-input-theme($light);
  @include initials-circle-theme($light);
  @include blockquote-theme($light);

  .cdk-drag-preview {
    background: white;
  }

  body {
    &.dark-theme {

      @include mat.all-component-colors($dark);
      @include event-week-schedule-item-mixin($dark);
      @include login-page-mixin(white);
      @include page-mixin($dark);
      @include time-input-theme($dark);
      @include initials-circle-theme($dark);
      @include blockquote-theme($dark);

      //$config: mat-get-color-config($dark);
      //$background: map-get($config, background);

      .cdk-drag-preview {
        background: mat.get-color-from-palette(map-get($dark, background), card);
        color: white;
      }

      $background: map.get($dark, background);

      .mat-toolbar {
        background: mat.get-color-from-palette($background, app-bar) !important;
        color: white;
      }
    }
  }
}

