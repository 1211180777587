tremaze-routed-tab-view {
  display: flex;
  flex-direction: column;
  height: 100%;

  .routeProxy {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  router-outlet ~ *:not(.routeProxy) {
    flex: 1;
    position: static;
    height: auto;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;

    > * {
      flex: 1;
    }
  }
}
