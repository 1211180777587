@use '@angular/material' as mat;
@use 'sass:map';

@mixin expanding-icon-text-input-theme($theme) {
  $accent: map.get($theme, accent);
  $accent-color: mat.get-color-from-palette($accent);
  tremaze-expanding-icon-text-input {

    color: white;

    input {
      background-color: rgba($accent-color, .3);
      color: black;
    }

    span {
      background-color: $accent-color;
    }
  }
}
